<template>
  <!-- web 权限控制 -->
  <div class="permissions-content dp_f">
    <div class="all-menu single active">
      <div class="title">
        全部菜单模块
        <span class="sub-text">（可添加需要功能至已有权限中）</span>
      </div>
      <div class="permissions-drg">
        <div class="menu-one">
          <div
            class="menu-one-single cur_p"
            @click.stop="menuOneCheck(item)"
            :class="[ menuChecked === index ? 'active' : '']"
            v-for="(item,index) in menuList"
            :key="item.menuId"
          >
            <i class="icon iconfont" :class="item.icon"/>
            <div class="name">{{ item.title }}</div>
            <div class="menu-add-btn cur_p" @click.stop="addMenu(item, 1)" v-show='!isHave(item,1) && item.childList.length > 0'>+</div>
          </div>
        </div>
        <div class="menu-two" v-if="menuChecked >= 0 && menuList.length > 0">
          <div
            class="menu-two-item"
            v-for="item in menuList[menuChecked].childList"
            @click.stop="addMenu(item, 2)"
            :key="item.menuId"
          >
            {{ item.title }}
            <div class="menu-add-btn cur_p" v-show='!isHave(item,2)'>+</div>
          </div>
        </div>
      </div>
    </div>
    <div class="has-menu single">
      <div class="title">已有权限</div>
      <div class="permissions-drg">
        <div class="menu-one">
          <div
            class="menu-one-single cur_p"
            :class="[myMenuChecked === index ? 'active' : '']"
            v-for="(item,index) in myMenuList"
            :key="item.menuId"
            @click.stop='myMenuCheck(item)'
          >
            <i class="icon  iconfont" :class="item.icon"/>
            <div class="name">{{ item.title }}</div>
             <div class="menu-add-btn cur_p" @click.stop="delMenu(item, 1)" v-show='item.menuId != 36 '>-</div>
          </div>
        </div>
        <div class="menu-two" v-if="myMenuChecked >= 0 && myMenuList.length > 0">
          <div
            class="menu-two-item"
            v-for="item in myMenuList[myMenuChecked].childList"
            :key="item.menuId"
            @click.stop="delMenu(item, 2)"
          >
            {{ item.title }}
            <div class="menu-add-btn cur_p" v-show='item.menuId !=37'>-</div>
          </div>
        </div>
        <!-- <div class="menu-del cur_p">
          <img src="../../../../assets/img/person/del.png" />
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "permissionsWeb",
};
</script>
<script setup>
import {ref, toRefs, getCurrentInstance} from "vue";
import { getMenuList } from "@/utils/api/system";
import { menuAll } from '@/utils/api/person/role.js'
import { cloneDeep } from "lodash";
const props = defineProps({
  roleInfo: {
    type: Object,
    default: () => {}
  }
})
const { proxy } = getCurrentInstance();
const { roleInfo } = toRefs(props);
const menuChecked = ref(-1);
const menuList = ref([]);
const myMenuChecked = ref(-1);
const myMenuList = ref([]);
const appType = 1

const oldMenuList = ref([]);
const menuOneCheck = (e) => {
  if (!e.childList) {
    menuChecked.value = -1;
    return};
  let index = menuList.value.findIndex((item) => item.menuId === e.menuId);
  menuChecked.value = index;
};

const myMenuCheck =(e)=>{
  if (!e.childList) {
    myMenuChecked.value = -1;
    return};
  let index = myMenuList.value.findIndex((item) => item.menuId === e.menuId);
  myMenuChecked.value = index;
}
//获取导航
const getMenu = async () => {
  let data = await menuAll({
    appType,
    role:roleInfo.value.role
  });
  if (data.code === 0 && data.data.list) {
    let list =data.data.list;
    if(list.length > 0){
      list.forEach(item=>{
        if(item.childList && item.childList.length > 0){
          item.childList.forEach(item2=>{
            item2.parentId = item.menuId
          })
        }else{
          item.childList = []
        }
      })
    }
    menuList.value =cloneDeep(list) ;
  }
};

const getMyMenu = async ()=>{
  let data = await getMenuList({
    appType,
    role:roleInfo.value.role
  });
  if (data.code === 0 && data.data.list) {
    let list =data.data.list;
    if(list.length > 0){
      list.forEach(item=>{
        if(item.childList && item.childList.length > 0){
          item.childList.forEach(item2=>{
            item2.parentId = item.menuId
          })
        }else{
          item.childList = []
        }
      })
    }
    myMenuList.value =cloneDeep(list) ;
    oldMenuList.value =cloneDeep(list) ;
  }
}

const isHave = (e,type) => {
    //type = 1 一级菜单，2，二级菜单
    if (type == 1) {
      let index = myMenuList.value.findIndex((item) => item.menuId === e.menuId);
      if (index == -1) {
        return false;
      }else{
        return true;
      }
    } else {
      let index = myMenuList.value.findIndex(
        (item) => item.menuId === e.parentId
      );
      if (index == -1) {
        return false;
      } else {
        let index2 = myMenuList.value[index].childList.findIndex(
          (item) => item.menuId === e.menuId
        );
        if (index2 == -1) {
          return false;
        }else{
          return true;
        }
      }
    }
};

const addMenu = (e, type) => {
  let obj = cloneDeep(e);
  //type = 1 一级菜单，2，二级菜单
  if (type == 1) {
    if(e.childList && e.childList.length > 0){
         let index = myMenuList.value.findIndex((item) => item.menuId === e.menuId);
      if (index == -1) {
        myMenuList.value.push(obj);
      }
    }
  } else {
    let index = myMenuList.value.findIndex(
      (item) => item.menuId === e.parentId
    );
    if (index == -1) {
      let activemenu =cloneDeep(menuList.value.find(
        (item) => item.menuId === e.parentId
      ))
      activemenu.childList = [obj];
      myMenuList.value.push(activemenu);
    } else {
      let index2 = myMenuList.value[index].childList.findIndex(
        (item) => item.menuId === e.menuId
      );
      if (index2 == -1) {
        myMenuList.value[index].childList.push(obj);
      }
    }
  }
  //myMenuList.value根据menuId排序
  myMenuList.value.sort((a,b)=>{
    return a.menuId - b.menuId
  })
};

const delMenu = (e,type)=>{
  if(e.menuId == 37 ) return
  //type = 1 一级菜单，2，二级菜单
  if (type == 1) {
    myMenuChecked.value = -1;
    let index = myMenuList.value.findIndex((item) => item.menuId === e.menuId);
    if (index != -1) {
      myMenuList.value.splice(index,1);
    }
  } else {
    let index = myMenuList.value.findIndex(
      (item) => item.menuId === e.parentId
    );
    if (index != -1) {
      let index2 = myMenuList.value[index].childList.findIndex(
        (item) => item.menuId === e.menuId
      );
      if (index2 != -1) {
        myMenuList.value[index].childList.splice(index2,1);
      }
      //如果删除的是最后一个，则删除一级菜单 并且移除掉myMenuChecked
      if(myMenuList.value[index].childList.length == 0){
        myMenuList.value.splice(index,1);
        myMenuChecked.value = -1;
      }
    }
  }
}

const upDateInfo = ()=>{
  let addMenu = []
  let delMenu = []
  //比较myMenuList.value 和 oldMenuList.value 新增和删除的数据无论是一级还是二级都添加到addMenu和delMenu中
  myMenuList.value.forEach(item=>{
    let index = oldMenuList.value.findIndex((item2) => item2.menuId === item.menuId);
    if(index == -1){
      addMenu.push(item)
    }else{
      if(item.childList && item.childList.length > 0){
        item.childList.forEach(item2=>{
          let index2 = oldMenuList.value[index].childList.findIndex((item3) => item3.menuId === item2.menuId);
          if(index2 == -1){
            addMenu.push(item2)
          }
        })
      }
    }
  })
  oldMenuList.value.forEach(item=>{
    let index = myMenuList.value.findIndex((item2) => item2.menuId === item.menuId);
    if(index == -1){
      delMenu.push(item)
    }else{
      if(item.childList && item.childList.length > 0){
        item.childList.forEach(item2=>{
          let index2 = myMenuList.value[index].childList.findIndex((item3) => item3.menuId === item2.menuId);
          if(index2 == -1){
            delMenu.push(item2)
          }
        })
      }
    }
  })
let addWebList = cloneDeep(addMenu)
  let addWeb = []
  if(addWebList.length > 0){
    addWebList.forEach(item => {
      addWeb.push(item.menuId)
      if(item.childList){
         item.childList.forEach(item2 => {
          addWeb.push(item2.menuId)
        });
      }
    });
  }
  let delWebList = cloneDeep(delMenu)
  let delWeb = []
  if(delWebList.length > 0){
    delWebList.forEach(item => {
       delWeb.push(item.menuId)
      if(item.childList){
         item.childList.forEach(item2 => {
          delWeb.push(item2.menuId)
        });
      }
    });
  }
   addWeb = [...new Set(addWeb)]

  //delWeb去重
  delWeb = [...new Set(delWeb)]
  return{
    addWeb,
    delWeb
  }
}

defineExpose({
  upDateInfo,
  getMenu,
  getMyMenu,
})
</script>

<style lang="scss">
.permissionsInfo {
  .permissions-btn-box {
    display: flex;
    justify-content: flex-end;
    margin-top: 22px;
    .permissions-btn {
      width: 112px;
    }
  }
  .permissions {
    padding: 0 16px;
    .permissions-content {
      padding-top: 16px;
      > .single {
        width: 660px;
        background-color: #f7fafc;
        border-radius: 8px;
        box-sizing: border-box;
        padding: 22px;
        height: 650px;
        position: relative;
        .menu-add-btn {
          width: 20px;
          height: 20px;
          background-color: #3859ff;
          color: #fff;
          text-align: center;
          line-height: 21px;
          font-size: 16px;
          border-radius: 20px;
          position: absolute;
          top: -10px;
          right: -10px;
        }
        .title {
          font-size: 16px;
          color: $fsColor;
          .sub-text {
            color: #aaaab2;
            font-size: 12px;
          }
        }
        &.has-menu {
          &.active {
            background-color: #f3f5ff;
            border: 1px solid #3859ff;
          }
        }
        .my-menu {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          .ghost {
            display: none;
          }
        }
        .menu-one {
          display: flex;
          align-items: center;
          margin-top: 20px;
          height: 80px;
          .chosenClass {
            background-color: #3859ff !important;
            color: #fff !important;
          }
          .menu-one-single {
            width: 80px;
            height: 80px;
            padding-top: 18px;
            box-sizing: border-box;
            text-align: center;
            color: $fsColor;
            margin-right: 8px;
            background-color: #fff;
            border: 1px solid #dcdee0;
            border-radius: 8px;
            position: relative;

            &.active {
              border: 1px solid #3859ff;
              color: #3859ff;
              &:after {
                content: "";
                position: absolute;
                display: inline-block;
                bottom: -17px;
                left: 50%;
                transform: translateX(-50%);
                width: 14px;
                height: 7px;
                background: url("../../../../assets/img/person/arrow.png")
                  center center no-repeat;
                background-size: contain;
              }
            }
            &:hover {
              border: 1px solid #b2bfff;
              color: #b2bfff;
            }
            .icon {
              font-size: 16px;
            }
            .name {
              font-size: 14px;
              margin-top: 14px;
            }
          }
        }
        .menu-two {
          margin-top: 16px;
          background: #ffffff;
          border: 1px solid #3859ff;
          display: flex;
          align-items: center;
          padding: 16px;
          border-radius: 8px;
          .menu-two-item {
            width: 96px;
            height: 40px;
            background: #f2f4f7;
            border-radius: 8px;
            text-align: center;
            line-height: 40px;
            margin-right: 16px;
            font-size: 14px;
            color: #737480;
            position: relative;
          }
        }
        .menu-del {
          position: absolute;
          bottom: 20px;
          left: 50%;
          transform: translateX(-50%);
          width: 64px;
          height: 64px;
          background: linear-gradient(210deg, #4775ff 0%, #3859ff 100%);
          border-radius: 64px;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            width: 24px;
            height: 38px;
            position: relative;
            top: -4px;
          }
        }
      }
    }
  }
}
</style>
