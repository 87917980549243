<template>
<!-- 新增编辑角色 -->
    <el-dialog
      v-bind="$attrs"
      title="删除提示"
      width="640"
      class="roleInfo"
      :close-on-click-modal="false"
      destroy-on-close
      align-center
      @close="closeDia"
      @open="openDia"
      >
      <div class="role-delelt-content">
        <div class="delelt-content">是否确认删除该角色</div>
        <div class="role-user" v-if='userList.length > 0'>
          <div class="role-user-title">角色对应使用用户：</div>
          <div class="role-user-content">
            <div class="role-user-single" v-for="(item,index) in userList" :key="index">
              <img src="https://oss.musaemotion.com/WEB/assets/img/index/userPic.png" class="role-user-pic">
              <div class="role-user-name">{{item}}</div>
            </div>
        </div>
      </div>
      </div>
     <template #footer >
        <el-button class="btn1 zh-btn" @click='closeDia'>取消</el-button>
        <el-button class="btn1" type="primary" @click='handleDel'>确认</el-button>
      </template>
  </el-dialog>
</template>

<script setup>
import { roleUserList,roleDel } from '@/utils/api/person/role.js'
import {ref, toRefs, reactive, getCurrentInstance, onMounted} from 'vue'
const { proxy } = getCurrentInstance()
const props = defineProps({
  roleInfo: {
    type: Object,
    default: () => {}
  }
})
const { roleInfo } = toRefs(props)
const emit = defineEmits(['close'])
const userList = ref([])
const getUserList = ()=>{
  roleUserList({
    role:roleInfo.value.role
  }).then((res)=>{
    if(res.code != 0){
      proxy.$message.error(res.msg)
      return
    }
    userList.value = res?.data?.list || []
  })
}

const handleDel = ()=>{
  roleDel({
    role:roleInfo.value.role
  }).then((res)=>{
    if(res.code != 0){
      proxy.$message.error(res.msg)
      return
    }
    proxy.$message.success('删除成功')
    closeDia()
  })
}
const openDia = ()=>{
  getUserList()
}
const closeDia = ()=>{
  userList.value = []
  emit('close')
}
</script>
<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name:"addRole",
})
</script>

<style lang="scss" scoped>
.roleInfo{
  .role-delelt-content{
    padding: 16px;
    .delelt-content{
      text-align: center;
     color: $fsColor;
      font-size: 16px;
    }
    .role-user{
      margin-top: 26px;
      background-color: #F7FAFC;
      border-radius: 8px;
      padding-bottom: 16px;
      .role-user-title{
        color: $fsColor;
        padding: 24px;
      }
      .role-user-content{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-template-rows: 14px 14px;
        gap: 40px;
        padding: 16px 24px 0;
        @include overFlow();
        max-height: 100px;
        box-sizing: border-box;
        .role-user-single{
            display: flex;
            flex-wrap: wrap;
            align-content: center;
          .role-user-pic{
            width: 24px;
            height: 24px;
            border-radius: 8px;
            overflow: hidden;
          }
          .role-user-name{
            margin-left: 8px;
            color: $fsColor;
            font-size: 12px;
            @include textOverflow(60px);
            line-height: 24px;
          }
        }
      }
    }
  }
}
</style>