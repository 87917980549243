<template>
  <!--  权限弹窗-->
  <el-dialog
    v-bind="$attrs"
    title="权限配置"
    width="1400"
    class="permissionsInfo"
    :close-on-click-modal="false"
    destroy-on-close
    align-center
    @close="closeDia"
    @open="openDia"
  >
    <div class="permissions">
      <el-tabs v-model="tabChecked" class="demo-tabs" @tab-click="tabCheck">
        <el-tab-pane label="WEB平台" name="web"></el-tab-pane>
        <el-tab-pane label="PDA终端" name="pda"></el-tab-pane>
      </el-tabs>
      <Web v-show="tabChecked === 'web'" :roleInfo='roleInfo'  ref='webref'/>
      <Pda v-show="tabChecked === 'pda'" :roleInfo='roleInfo'  ref='pdaref'/>
      <div class="permissions-btn-box">
        <el-button type="primary" class="btn1 permissions-btn" @click='save'>保存</el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "permissions",
};
</script>
<script setup>
import {ref, toRefs, reactive, getCurrentInstance, onMounted} from 'vue'
import {roleMenuSave } from '@/utils/api/person/role.js'
import Web from "./web.vue";
import Pda from './pda.vue';
import{cloneDeep} from 'lodash'
const props = defineProps({
  roleInfo: {
    type: Object,
    default: () => {}
  }
})
const { roleInfo } = toRefs(props)
const { proxy } = getCurrentInstance();
const emit = defineEmits(['close'])
const webref = ref(null);
const pdaref = ref(null);
const menuChecked = ref(0);
const menuList = ref([]);
const menuListTwo = ref([]);
const oldMenuList = ref([]);
const myArr = ref([])
const myListOne = ref([]);
const myListTwo = ref([]);
const tabChecked = ref("web");
const tabCheck = (e) => {
};
const openDia = () => {
  tabChecked.value = 'web'
  webref.value.getMenu()
  webref.value.getMyMenu()
  pdaref.value.getMenu()
  pdaref.value.getMyMenu()
};
const save = ()=>{
  let addWebList = cloneDeep(webref.value.upDateInfo().addWeb)
  let delWebList = cloneDeep(webref.value.upDateInfo().delWeb)
  let addPdaList = cloneDeep(pdaref.value.upDateInfo().addPda)
  let delPdaList = cloneDeep(pdaref.value.upDateInfo().delPda)
  roleMenuSave({
    role:roleInfo.value.role,
    addMenu:[...addWebList,...addPdaList],
    delMenu:[...delWebList,...delPdaList]
  }).then((res)=>{
    if(res.code != 0){
      proxy.$message.error(res.msg)
      return
    }
    proxy.$message.success('保存成功')
    closeDia()
  })
}
const closeDia = ()=>{
  emit('close')
}

</script>

<style lang="scss">
.permissionsInfo {
  .permissions-btn-box {
    display: flex;
    justify-content: flex-end;
    margin-top: 22px;
    .permissions-btn {
      width: 112px;
    }
  }
  .permissions {
    padding: 0 16px;
    .permissions-content {
      padding-top: 16px;
      > .single {
        width: 660px;
        background-color: #f7fafc;
        border-radius: 8px;
        box-sizing: border-box;
        padding: 22px;
        height: 650px;
        position: relative;
        .title {
          font-size: 16px;
          color: $fsColor;
          .sub-text {
            color: #aaaab2;
            font-size: 12px;
          }
        }
        &.has-menu{
          &.active{
            background-color: #F3F5FF;
            border: 1px solid #3859FF;
          }
        }
        .my-menu{
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          .ghost{
           display: none;
          }
        }
        .menu-one {
          display: flex;
          align-items: center;
          margin-top: 20px;
          height: 80px;
          .chosenClass{
            background-color: #3859FF !important;
            color: #fff !important;
          }
          .menu-one-single {
            width: 80px;
            height: 80px;
            padding-top: 18px;
            box-sizing: border-box;
            text-align: center;
            color: $fsColor;
            margin-right: 8px;
            background-color: #fff;
            border: 1px solid #dcdee0;
            border-radius: 8px;
            &.active {
              position: relative;
              border: 1px solid #3859ff;
              color: #3859ff;
              &:after {
                content: "";
                position: absolute;
                display: inline-block;
                bottom: -17px;
                left: 50%;
                transform: translateX(-50%);
                width: 14px;
                height: 7px;
                background: url("../../../../assets/img/person/arrow.png")
                  center center no-repeat;
                background-size: contain;
              }
            }
            &:hover {
              border: 1px solid #b2bfff;
              color: #b2bfff;
            }
            .icon {
              font-size: 16px;
            }
            .name {
              font-size: 14px;
              margin-top: 14px;
            }
          }
        }
        .menu-two {
          margin-top: 16px;
          background: #ffffff;
          border: 1px solid #3859ff;
          display: flex;
          align-items: center;
          padding: 16px;
          border-radius: 8px;
          .menu-two-item {
            width: 96px;
            height: 40px;
            background: #f2f4f7;
            border-radius: 8px;
            text-align: center;
            line-height: 40px;
            margin-right: 16px;
            font-size: 14px;
            color: #737480;
          }
        }
        .menu-del {
          position: absolute;
          bottom: 20px;
          left: 50%;
          transform: translateX(-50%);
          width: 64px;
          height: 64px;
          background: linear-gradient(210deg, #4775ff 0%, #3859ff 100%);
          border-radius: 64px;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            width: 24px;
            height: 38px;
            position: relative;
            top: -4px;
          }
        }
      }
    }
  }
}
</style>
