<template>
<!-- 角色管理 -->
  <div class="zh-main-app ">
     <div class="zh-main-content roleList">
      <div class="queryBoxOne">
        <div class="btnBox">
          <el-button type="primary" @click='openAddRole'>
            <span class="text">新增角色</span>
          </el-button>
        </div>
        <div class="queryItem">
          <el-input  v-elInput class="w240" placeholder="角色名称模糊搜索" @keydown.enter="getList" v-model="formData.query" clearable>
          </el-input>
          <div class="queryBtn">
          <el-button type="primary" @click="getList"
            ><el-icon><Search /></el-icon
          ></el-button>
        </div>
        </div>
      </div>
      <div class="tabList">
        <div class="role-list" v-if='tableData.length > 0'>
          <div class="single cur_p" v-for="item in tableData" :key="item.role">
            <div class="role-name">
              <img src="https://oss.musaemotion.com/WEB/assets/img/index/userPic.png" class="pic">
              <div class="name">{{item.roleName}}</div>
            </div>
            <div class="role-desc">
              <div class="text">角色描述：</div>
              <div class="desc">{{item.desc}}</div>
            </div>
            <div class="role-control">
              <div class="control-btn" @click='editRole(item)'>编辑</div>
              <div class="control-btn" @click='openDelRole(item)'>删除</div>
              <div class="control-btn" @click='roleSet(item)'>权限配置</div>
            </div>
          </div>
        </div>
        <div class="zh_empty" v-else></div>
      </div>
     </div>
  </div>
  <AddRole :roleInfo='roleInfo' v-model="addShow" @close='closeDia'></AddRole>
  <DelRole :roleInfo='roleInfo' v-model="delShow" @close='closeDia'></DelRole>
  <Permissions :roleInfo='roleInfo' v-model="permissionsShow" @close='closeDia'></Permissions>
</template>

<script setup>
import Permissions from "./components/permissions";
import AddRole from './components/addRole.vue';
import DelRole from './components/delModal.vue';
import { ref, reactive, getCurrentInstance, onMounted } from 'vue'
import { Search } from "@element-plus/icons-vue";
import {roleList } from '@/utils/api/person/role.js'
const { proxy } = getCurrentInstance()
const formData = reactive({
  query: '',
})
const tableData = ref([
])
const permissionsShow = ref(false)
const getList = () => {
  roleList({
    roleName: formData.query,
  }).then((res) => {
    if (res.code != 0) {
     proxy.$message.error(res.msg)
      return
    }
    tableData.value = res?.data?.list || []
  })
}
const roleInfo = reactive({
  roleName:'',
  roleDesc:'',
  role:0,
})
const addShow = ref(false)
const delShow = ref(false)
const openAddRole = ()=>{
  roleInfo.roleName = ''
  roleInfo.desc = ''
  roleInfo.role = 0
  addShow.value = true
}
const roleSet = (e)=>{
  roleInfo.roleName = e.roleName
  roleInfo.desc = e.desc
  roleInfo.role = e.role
  permissionsShow.value = true
}
const openDelRole = (e)=>{
  roleInfo.roleName = e.roleName
  roleInfo.desc = e.desc
  roleInfo.role = e.role
  delShow.value = true
}
const editRole = (e)=>{
  roleInfo.roleName = e.roleName
  roleInfo.desc = e.desc
  roleInfo.role = e.role
  addShow.value = true
}
const closeDia = ()=>{
  delShow.value = false
  addShow.value = false
  permissionsShow.value = false
  getList()
}

onMounted(() => {
  getList()
})
</script>
<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name:"roleList",
})
</script>


<style lang="scss" scoped>
@import './index.scss';
</style>
