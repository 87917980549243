<template>
<!-- 新增编辑角色 -->
    <el-dialog
      v-bind="$attrs"
      :title="roleInfo.role?'编辑角色':'新增角色'"
      width="920"
      class="roleInfo"
      :close-on-click-modal="false"
      destroy-on-close
      align-center
      @close="closeDia"
      >
      <div class="role-content">
         <div class="role-content-item dp_f">
          <div class="role-content-left single">
            <div class="title"><span class="red">*</span>角色名称</div>
            <div class="role-input">
              <el-input v-elInput class="w360" maxlength="20" v-model="roleInfo.roleName" placeholder="请输入角色名称"></el-input>
            </div>
          </div>
          <div class="role-content-right single">
            <div class="title">角色描述</div>
            <div class="role-input">
              <el-input v-elInput class="w360 role-input-textarea" v-model='roleInfo.desc' maxlength="100"  type="textarea"  resize="none" placeholder="最大字符，100个字符"></el-input>
            </div>
          </div>
        </div>
      </div>
     <template #footer >
        <el-button class="btn1 zh-btn" @click='closeDia'>取消</el-button>
        <el-button class="btn1" type="primary" :disabled='!roleInfo.roleName' @click='handleOk'>保存</el-button>
      </template>
  </el-dialog>
</template>

<script setup>
import {ref, toRefs, reactive, getCurrentInstance} from 'vue'
import { roleAdd , roleEdit } from '@/utils/api/person/role.js'
const { proxy } = getCurrentInstance()
const props = defineProps({
  roleInfo: {
    type: Object,
    default: () => {}
  }
})
const { roleInfo } = toRefs(props)
const emit = defineEmits(['close'])
const closeDia = ()=>{
  emit('close')
}
const handleOk = ()=>{
  let api = roleInfo.value.role?roleEdit:roleAdd
  let params = {
    roleName:roleInfo.value.roleName,
    desc:roleInfo.value.desc,
    role:roleInfo.value.role || '',
  }
  api(params).then((res)=>{
    if(res.code != 0){
      proxy.$message.error(res.msg)
      return
    }
    proxy.$message.success('操作成功')
    emit('close')
  })
}
</script>
<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name:"addRole",
})
</script>

<style lang="scss" scoped src="./addRole.scss">
</style>